@charset "UTF-8";
h1, h2, h3, h4, h5, h6, p, blockquote, pre,a, abbr, acronym, address, big, cite, code,del, dfn, em, img, ins, kbd, q, s, samp,small, strike, strong, sub, sup, tt, var,b, u, i, center,dl, dt, dd, ol, ul, li,fieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, embed,figure, figcaption, footer, header, hgroup,menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption, th, td {
  text-align: left;
  font-weight: 500;
  vertical-align: middle;
}
q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
a img {
  border: none;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}
input,
select,
button {
  &,
  &:hover,
  &:active,
  &:visited,
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    background: none;
  }
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  overflow-wrap: break-word;
  word-break: break-word;
}

html, body {
  font-family:  "Noto Sans CJK JP", "Noto Sans JP", "Noto Sans CJK JP", "ヒラギノ角ゴ Pro", "游ゴシック", "游明朝", "ヒラギノ明朝 Pro", "Oswald", "メイリオ", "游ゴシック", "Noto Serif", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  line-height: 1.5;
  text-align: justify;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  width: 100%;
}

a,a:hover {
  text-decoration: none;
}
.wrapper {
    position: relative;
}
.main {
  padding-bottom: 134px;
}
.footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #707070;
    background: white;
    z-index: 10;

    @media (orientation: landscape) {
        padding-right: 44px;
        padding-bottom: 21px;
        padding-left: 44px;
    }
    
}
.c {
  &_navs {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      list-style: none;
      li {
          width: calc(100% / 3 );
          a {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              color: #d3d3d3;
              height: 100px;
              &.active {
                color: #c04361;
                svg path {
                  fill: #c04361;
                }
              }
              svg {
                  width: 30px;
              }
              span {
                  font-size: 13px;
                  font-weight: 700;
                  margin-top: 4px;
              }
          }
      }
  }
  &_meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cm_date {
      font-size: 16px;
    }
    .cm_alarm {
      display: block;
      position: relative;
      svg {
        width: 30px;
      }
      .has_alarm {
        width: 13px;
        height: 13px;
        background-color: #ac1f1f;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: -3px;
      }
    }
  }
  &_modal {
    width: calc(100% - 50px);
    border-radius: 12px;
    overflow: hidden;
    .cm_head {
      position: relative;
      h2 {
        padding: 20px 10px;
        background-color: #c04361;
        color: white;
        text-align: center;
        margin: 0;
        font-size: 23px;
      }
      .cm_close {
        position: absolute;
        top: calc(50% - 0.5em);
        font-size: 33px;
        left: 20px;
        width: 20px;
        line-height: 1;
        color: white;
        border: none;
        display: block;
        margin: 0;
        padding: 0;
      }
    }
    .cm_content {
      padding: 30px 40px 40px;
      background: white;
      .cmc_title {
        font-size: 12.5px;
        color: #c04361;
        text-align: center;
        margin-bottom: 20px;
        span {
          display: block;
        }
      }
      .cc_form {
        .cf_row {
          margin-bottom: 30px;
        }
      }
    }
  }
  &_faq {
      ul {
        max-width: calc(100% - 60px);
        margin-right: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        li {
            font-size: 14px;
            padding: 10px 20px;
            border: 1px solid #b7bec4;
            background: white;
            box-shadow: 0 0 6px rgba(0,0,0,0.15);
            display: block;
            margin-bottom: 10px;
            border-radius: 5px;
        }
      }
  }
  &_answer {
      ul {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex-direction: column;
          li {
              font-size: 14px;
              padding: 10px 20px;
              border: 1px solid #b7bec4;
              background: white;
              box-shadow: 0 0 6px rgba(0,0,0,0.15);
              display: block;
              margin-bottom: 10px;
              border-radius: 5px;
              background-color: #f4f4f4;
              margin: 5px 10px;
          }
      }
  }
  &_result {
    ul {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;
        li {
            font-size: 14px;
            padding: 10px 20px;
            border: 1px solid #c04361;
            background: white;
            box-shadow: 0 0 6px rgba(0,0,0,0.15);
            display: block;
            margin-bottom: 10px;
            border-radius: 5px;
            background-color: #c04361;
            margin: 5px 10px;
            color: white;
        }
    }
  }
  &_value_input {
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: #707070;
    border-top: 1px solid #707070;
    background: #e4e4e4;
    input {
      height: 40px;
      flex: 8;
      text-align: left;
      padding: 10px 0 10px 20px;
      margin: 5px;
      border-radius: 15px;
      border: none !important;
      font-size: 20px;
      background-color: #fff;
    }
    button {
      font-size: 16px;
      font-weight: 600;
      flex: 2;
      color: #fff;
      background-color: #c04361;
      line-height: 40px;
      height: 40px;
      margin: 5px 5px 5px 0;
      border-radius: 10px;
      box-shadow: none !important;
      outline: none !important;
      border: none !important;
    }
  }
  &_act {
    .ca_submit {
      font-size: 18px;
      padding: 15px 11px;
      line-height: 1;
      border: 1px solid #c04361;
      color: #c04361;
      text-align: center;
      display: block;
      box-shadow: none;
      border-radius: 9999px;
      background: white;
      width: 100%;
    }
  }
}
.cf {
  &_row {
    display: block;
    .cf_label {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .cf_text {
      border: 1px solid #707070;
      font-size: 15px;
      border-radius: 10px;
      height: 40px;
      display: block;
      margin: 0;
      padding: 9px 7px;
    }
    .cf_suffix {
      font-size: 15px;
    }
    .cfr_control {
      &--weight {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        input {
          width: 80px;
          margin-right: 10px;
        }
      }
      &--admin {
        input {
          width: 100px;
          margin-right: 10px;
        }
      }
    }
  }
}
.cb {
  &_input {
    width: 100%;
    height: 90px;
    border-image: 50%;
    background: #c04361 !important;
    border: none;
    color: white;
    font-size: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 3px rgba(0,0,0,0.16);
    border-radius: 9999px;
    &:disabled {
      background: black !important;
      color: white;
    }
  } 
}
#loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: white;
  &.submitting {
    opacity: 0.5;
  }
  .spiner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #06C755;
      border-radius: 50%;
      animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #06C755  transparent transparent transparent;
    }
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
#register {
    padding: 60px 25px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(255,255,255,0.95);
    overflow: auto;
    .c_faq,
    .c_answer,
    .c_result {
        margin-bottom: 10px;
    }
}
#top {
  padding: 25px 15px;
  .t_title {
    padding: 15px 5px;
    font-size: 20px;
    font-weight: 700;
  }
  .t_message {
    position: relative;
    padding-right: 40px;
    padding-bottom: 70px;
    .tm_text {
      padding: 30px 25px;
      border: 3px solid #e1cac0;
      border-radius: 14px;
      font-size: 16px;
      height: 300px;
    }
    .tm_media {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 124px;
    }
  }
  .t_input {
    margin-top: 10px;
  }
}
.ReactModal__Overlay {
  background: rgba(0,0,0,0.52) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
// plugin
.fc-theme-standard {
  border: none;
  .fc-header-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 16px !important;
    .fc-toolbar-chunk:nth-child(1) {
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          color: #C6C6C6;
          padding: 0;
          margin: 0;
          background: none;
          border: none;
          font-size: 14px;
        }
        .fc-toolbar-title {
          font-size: 17px;
          margin: 0 10px;
        }
      }
    }
    .fc-toolbar-chunk:nth-child(2),
    .fc-toolbar-chunk:nth-child(3) {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        color: #333333;
        &:before {
          content: "";
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 22px;
          height: 22px;
          margin-right: 5px;
          display: block;
        }
        font-size: 15px;
      }
      .fc-noyet-button {
        &:before {
          background-image: url(~/public/icon_yet.svg);
        }
      }
      .fc-done-button {
        &:before {
          background-image: url(~/public/icon_done.svg);
        }
      }
    }
  }
  .fc-dayGridMonth-view {
    .fc-scrollgrid {
      border: none;
    }
    th {
      border-top: none;
    }
    th, td {
      border-left: none;
      border-right: none;
      width: calc(100% / 7);
    }
    .fc-day {
      height: initial;
      &.fc-day-today {
        background: none;
        .fc-daygrid-day-top {
          .fc-daygrid-day-number {
            color: white;
            background: #C04362;
            border-radius: 9999px;
          }
        }
      }
      .fc-daygrid-day-top {
        position: absolute;
        left: 0;
        top: 2px;
        .fc-daygrid-day-number {
          font-size: 12px; 
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .fc-daygrid-day-frame {
        min-height: initial;
        height: 70px;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .fc-event,
        .fc-event {
          background: none;
          width: 26px;
          display: block;
          border: none;
          padding: 0;
        }
      }
    }
  }
}
#chartjs-tooltip {
  background: #707070;
  width: 60px;
  height: 50px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  line-height: 1.3;
  h2 {
    font-size: 9px;
    background: #707070;
    border-radius: 10px;
    color: white;
    text-align: center;
  }
  p {
    color: white;
    width: 100%;
    font-size: 10px;
    &.num {
      text-align: center; 
    }
    &.ja {
      text-align: right; 
    }
  }
}
#graph {
  padding: 23px 12px;
  .g_navs {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(208,171,155,0.2);
      border-radius: 5px;
      li {
        list-style: none;
        width: 20%;
        a {
          display: block;
          font-size: 12px;
          text-align: center;
          padding: 7px;
          border-radius: 4px;
          position: relative;
          color: #818181;
        }
        & ~ li {
          a {
            &:before {
              content: "";
              left: 0;
              top: 7px;
              bottom: 7px;
              height: calc(100% - 14px);
              width: 1px;
              background: #CECECE;
              position: absolute;
            }
          }
        }
        &.active{
          a {
              background: white;
              box-shadow: 0 3px 6px rgba(0,0,0,0.16);
          }
         }
        &.active + li,
        &.active {
          a:before {
            content: none;
          }
        }
      }
    }
  }
  .g_title {
    display: block;
    margin-top: 18px;
    font-size: 12px;
    text-align: center;
    color: #C04361;
    span {
      font-size: 1.5em;
    };
  }
}
#calendar {
  .c_title {
    color: #AC1F1F;
    font-size: 21px; 
    padding: 20px;
    text-align: center;
    line-height: 1.4;
    white-space: pre-line;
  }
  .c_calendar {
    border-top: 1px solid rgba(112,112,112,0.2);
    padding-top: 19px;
  }
  .t_input {
    padding: 20px 0 54px;
    max-width: calc(100vw - 24px);
    margin-left: auto;
    margin-right: auto;
  }
}
#health {
  .h_nav {
    position: sticky;
    top: 0;
    z-index: 2;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      background: white;
      li {
        width: 50%;
        list-style: none;
        a {
          display: block;
          width: 100%;
          font-size: 12px;
          padding: 30px 10px 10px;
          text-align: center;
          border-bottom: 3px solid transparent;
          &.active  {
            border-bottom-color: #C04361;
          }
        }
      }
    }
  }
}
#setting {
  padding: 25px 12px;
  .s_title {
    font-size: 16px;
  }
  .cm_form {
    margin-top: 30px;
    padding: 0 20px;
    .cm_frow {
      margin-bottom: 13px;
      .cmf_label {
        font-size: 12px;
      }
      .cmf_control {
        margin-top: 5px;
      }
      .cmf_text,
      .cmf_select {
        border: 1px solid #B7BEC4;
        border-radius: 5px;
        padding: 7px;
        height: 40px;
        margin-top: 4px;
        width: 100%;
        display: block;
        font-size: 16px;
        &.disabled {
          background :#f6f6f6;
        }
      }
      .drag_name{
          height: auto;
      }
    }
    .c_act {
      margin-top: 40px;
    }
  }
}